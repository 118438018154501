import { generatePath, useNavigate } from 'react-router-dom'

import { actions as commentsActions } from '@containers/comments/comments-slice'
import { useApi } from '@core/api'
import { getClient } from '@core/api/api-state'
import ErrorHandler from '@core/api/ErrorHandler'
import { ITEM_SET2 } from '@core/constants/content-type'
import { TabValues } from '@core/constants/generate-tab-values'
import {
  AUTHOR_CLOZE_ROUTE,
  AUTHOR_ITEM_ROUTE,
  AUTHOR_ITEM_SET,
  AUTHOR_ROUTE,
} from '@core/constants/routes'
import { useBffEnabled, useUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { useAppDispatch, useAppSelector } from '@core/store'
import { areInputsEnabled } from '@core/utils/tree'
import { ErrorMessage } from '@message/error'
import { createAction } from '@reduxjs/toolkit'
import { useMutation } from '@tanstack/react-query'

import * as queries from './author-queries'
import { actions } from './author-slice'
import { useBatches } from './batches/batches-state'
import { useResources } from './resources/resources-state'

export const authorClear = createAction('main/authorClear')

export const useGenerateItemSet = () => {
  const dispatch = useAppDispatch()
  const bffEnabled = useBffEnabled()
  const client = getClient()
  const navigate = useNavigate()
  const { role } = useUserRole()
  const author = useAppSelector((state) => state.author)
  const api = useApi()

  return useMutation({
    mutationKey: ['generateItemSet'],
    mutationFn: async (numberOfItems: number) => {
      dispatch(
        actions.set({
          paginatedItemsIds: [],
        }),
      )
      dispatch(commentsActions.set({ comments: [] }))
      navigate(AUTHOR_ROUTE)

      const {
        selectedModel,
        itemInputs,
        customPassageEnabled,
        customPassage,
        selectedFlavors,
        selectedCreativity,
        addCustomInput,
      } = author

      let variables: queries.GenerateItemParams = {
        modelId: selectedModel?.id,
        itemsRequired: numberOfItems,
        flavors: selectedFlavors,
      }

      let addInputs = false

      if (selectedModel) {
        addInputs = areInputsEnabled(selectedModel.flavors, selectedFlavors) && addCustomInput
        variables = {
          ...variables,
          // @ts-ignore
          inputs: addInputs ? itemInputs : {},
        }
      }

      // Add custom passage if enabled
      if (customPassageEnabled) {
        variables = {
          ...variables,
          customPassage,
        }
      }

      // Add creativity if it's defined
      if (selectedCreativity !== undefined) {
        variables = {
          ...variables,
          // @ts-ignore
          temperaturePercentage: selectedCreativity,
        }
      }

      const isItemSet2 = selectedModel?.type === ITEM_SET2
      const route = isItemSet2 ? AUTHOR_ITEM_SET : AUTHOR_CLOZE_ROUTE

      // Handle ITEM_SET2 and ITEM_SET types
      if (bffEnabled.generateItemSet) {
        const { data } = await api.generateItemSet({
          body: {
            aiModelId: selectedModel?.id,
            ...(variables.customPassage && { customPassage: variables.customPassage }),
            inputs: variables.inputs,
            submodels: variables?.flavors as [],
            ...(variables.meta && { meta: variables.meta }),
            temperaturePercentage: variables?.temperaturePercentage,
            itemsRequired: variables.itemsRequired,
          },
        })

        const itemsIds = data?.itemsIds || []
        navigate(generatePath(route, { itemId: itemsIds[0] }))

        return itemsIds
      }

      const { data } = await client.mutate({
        mutation: queries.GENERATE_ITEM_SET,
        variables,
        context: { role },
      })

      const itemsIds = data.generate_item_set?.itemsIds

      // Navigate based on the type of item set
      navigate(generatePath(route, { itemId: itemsIds[0] }))

      return itemsIds
    },
    onError: (error) => {
      const { message } = ErrorHandler(error)
      addSnack({ message, severity: 'error' })
    },
  })
}

export const useGenerateItem = () => {
  const dispatch = useAppDispatch()
  const bffEnabled = useBffEnabled()
  const client = getClient()
  const navigate = useNavigate()
  const { role } = useUserRole()
  const api = useApi()
  const author = useAppSelector((state) => state.author)

  const {
    selectedModel,
    itemInputs,
    customPassageEnabled,
    customPassage,
    selectedFlavors,
    selectedCreativity,
    addCustomInput,
  } = author

  return useMutation({
    mutationKey: ['generateItem'],
    mutationFn: async (numberOfItems: number) => {
      dispatch(
        actions.set({
          paginatedItemsIds: [],
        }),
      )
      dispatch(commentsActions.set({ comments: [] }))
      navigate(AUTHOR_ROUTE)

      let variables: queries.GenerateItemParams = {
        modelId: selectedModel?.id,
        itemsRequired: numberOfItems,
        flavors: selectedFlavors,
      }

      let addInputs = false

      if (selectedModel) {
        addInputs = areInputsEnabled(selectedModel.flavors, selectedFlavors) && addCustomInput
        variables = {
          ...variables,
          // @ts-ignore
          inputs: addInputs ? itemInputs : {},
        }
      }

      // Add custom passage if enabled
      if (customPassageEnabled) {
        variables = {
          ...variables,
          customPassage,
        }
      }

      // Add creativity if it's defined
      if (selectedCreativity !== undefined) {
        variables = {
          ...variables,
          // @ts-ignore
          temperaturePercentage: selectedCreativity,
        }
      }

      dispatch(
        actions.setLoading({
          generate: true,
        }),
      )

      const { resourceSettings } = selectedModel || {}

      if (
        resourceSettings?.item_starter &&
        itemInputs?.[resourceSettings.citation_field] &&
        addInputs
      ) {
        variables.resourceId = useResources.getState().selection?.match.root_id
      }

      if (bffEnabled.generate) {
        const { data, error } = await api.generate({
          body: {
            aiModelId: selectedModel?.id,
            ...(variables.customPassage && { customPassage: variables.customPassage }),
            inputs: variables.inputs,
            submodels: variables.flavors,
            ...(variables.meta && { meta: variables.meta }),
            temperaturePercentage: variables?.temperaturePercentage,
            itemsRequired: variables.itemsRequired,
            ...(variables.resourceId && { resourceId: variables.resourceId }),
          },
        })

        if (error) {
          throw error
        }

        if (data) {
          const { itemsIds, jobId } = data

          if (itemsIds.length === 1) {
            navigate(generatePath(AUTHOR_ITEM_ROUTE, { itemId: itemsIds[0] }))
          }
          if (itemsIds.length > 1) {
            // this creates a temporary batch item on the list
            // so we can avoid loaders while generating multiple items
            useBatches.getState().createTempBatch({
              id: jobId,
              itemsRequired: numberOfItems,
              aiModel: selectedModel!,
              selectedSubmodels: selectedFlavors,
              createdAt: new Date().toISOString(),
              firstDone: [],
            })

            dispatch(actions.set({ selectedTab: TabValues.TAB_BATCHES }))
            dispatch(actions.stopLoading())
          }
        }
      } else {
        const { data } = await client.mutate({
          mutation: queries.GENERATE_ITEM,
          variables,
          context: { role },
        })

        if (data?.generate_item) {
          const { itemsIds, jobId } = data.generate_item

          if (itemsIds.length === 1) {
            navigate(generatePath(AUTHOR_ITEM_ROUTE, { itemId: itemsIds[0] }))
          }
          if (itemsIds.length > 1) {
            // this creates a temporary batch item on the list
            // so we can avoid loaders while generating multiple items
            useBatches.getState().createTempBatch({
              id: jobId,
              itemsRequired: numberOfItems,
              aiModel: selectedModel!,
              selectedSubmodels: selectedFlavors,
              createdAt: new Date().toISOString(),
              firstDone: [],
            })

            dispatch(actions.set({ selectedTab: TabValues.TAB_BATCHES }))
            dispatch(actions.stopLoading())
          }
        }
      }
    },
    onError: () => {
      addSnack({
        message: ErrorMessage.api.defaultError,
        severity: 'error',
      })
    },
  })
}
