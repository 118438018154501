import { batch } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { actions as commentsActions } from '@containers/comments/comments-slice'
import { selectUserRole } from '@containers/main/main-utils'
import ErrorHandler from '@core/api/ErrorHandler'
import { ITEM_SET, ITEM_SET2 } from '@core/constants/content-type'
import { TabValues } from '@core/constants/generate-tab-values'
import {
  AUTHOR_CLOZE_ROUTE,
  AUTHOR_ITEM_ROUTE,
  AUTHOR_ITEM_SET,
  AUTHOR_ROUTE,
} from '@core/constants/routes'
import { addSnack } from '@core/snack/snack-state'
import { areInputsEnabled } from '@core/utils/tree'
import { ErrorMessage } from '@message/error'
import { createAsyncThunk } from '@reduxjs/toolkit'

import * as queries from './author-queries'
import { actions } from './author-slice'
import { useBatches } from './batches/batches-state'
import { useResources } from './resources/resources-state'

export const generateItem = createAsyncThunk(
  'author/generateItem',
  async (numberOfItems: number, { extra, getState, dispatch }) => {
    dispatch(
      actions.set({
        paginatedItemsIds: [],
      }),
    )
    dispatch(commentsActions.set({ comments: [] }))

    extra.navigate(AUTHOR_ROUTE)
    const { role } = selectUserRole(getState())

    const {
      selectedModel,
      itemInputs,
      customPassageEnabled,
      customPassage,
      selectedFlavors,
      selectedCreativity,
      addCustomInput,
    } = getState().author

    let variables: queries.GenerateItemParams = {
      modelId: selectedModel?.id,
      itemsRequired: numberOfItems,
      flavors: selectedFlavors,
    }

    let addInputs = false

    if (selectedModel) {
      addInputs = areInputsEnabled(selectedModel.flavors, selectedFlavors) && addCustomInput
      variables = {
        ...variables,
        // @ts-ignore
        inputs: addInputs ? itemInputs : {},
      }
    }

    if (customPassageEnabled) {
      variables = {
        ...variables,
        // @ts-ignore
        customPassage,
      }
    }
    if (selectedCreativity !== undefined) {
      variables = {
        ...variables,
        // @ts-ignore
        temperaturePercentage: selectedCreativity,
      }
    }

    if (selectedModel && [ITEM_SET2, ITEM_SET].includes(selectedModel.type)) {
      try {
        const { data } = await extra.client.mutate({
          mutation: queries.GENERATE_ITEM_SET,
          variables,
          context: { role },
        })

        const { items_ids: itemsIds } = data.generate_item_set
        const isItemSet2 = selectedModel.type === ITEM_SET2

        if (isItemSet2) {
          extra.navigate(generatePath(AUTHOR_ITEM_SET, { itemId: itemsIds[0] }))
        } else {
          extra.navigate(generatePath(AUTHOR_CLOZE_ROUTE, { itemId: itemsIds[0] }))
        }

        return itemsIds
      } catch (error) {
        ErrorHandler(error)
        addSnack({
          message: ErrorMessage.api.defaultError,
          severity: 'error',
        })
        throw error
      }
    }

    // Injects the resource id if the model has resource item starter
    // and the user has selected a resource from there
    // Do not send it if the user cleared the citation field (check if it's filled)
    const { resourceSettings } = selectedModel || {}

    if (
      resourceSettings?.item_starter &&
      itemInputs?.[resourceSettings.citation_field] &&
      addInputs
    ) {
      variables.resourceId = useResources.getState().selection?.match.root_id
    }

    try {
      const { data } = await extra.client.mutate({
        mutation: queries.GENERATE_ITEM,
        variables,
        context: { role },
      })

      if (data?.generate_item) {
        const { itemsIds, jobId } = data.generate_item

        if (itemsIds.length === 1) {
          extra.navigate(generatePath(AUTHOR_ITEM_ROUTE, { itemId: itemsIds[0] }))
        }
        if (itemsIds.length > 1) {
          // this creates a temporary batch item on the list
          // so we can avoid loaders while generating multiple items
          useBatches.getState().createTempBatch({
            id: jobId,
            itemsRequired: numberOfItems,
            aiModel: selectedModel!,
            selectedSubmodels: selectedFlavors,
            createdAt: new Date().toISOString(),
            firstDone: [],
          })

          batch(() => {
            dispatch(actions.set({ selectedTab: TabValues.TAB_BATCHES }))
            dispatch(actions.stopLoading())
          })
        }
      }
    } catch (error) {
      addSnack({
        message: ErrorMessage.api.defaultError,
        severity: 'error',
      })
      throw error
    }
  },
)
